/*
* * Leaflet Gesture Handling **
* * Version 1.3.0
*/
import { Map, Handler, DomUtil, DomEvent } from 'leaflet/dist/leaflet-src.esm'
import LanguageContent from './locales'


Map.mergeOptions({
  gestureHandlingOptions: {
    text: {},
    duration: 1000
  }
})

let draggingMap = false

export const GestureHandling = Handler.extend({
  addHooks: function () {
    this._handleTouch = this._handleTouch.bind(this)
    this._handleMouseOver = this._handleMouseOver.bind(this)
    this._handleMouseOut = this._handleMouseOut.bind(this)

    this._setupPluginOptions()
    this._setLanguageContent()
    this._disableInteractions()

    //Uses native event listeners instead of L.DomEvent due to issues with Android touch events
    //turning into pointer events
    this._map._container.addEventListener('touchstart', this._handleTouch)
    this._map._container.addEventListener('touchmove', this._handleTouch)
    this._map._container.addEventListener('touchend', this._handleTouch)
    this._map._container.addEventListener('touchcancel', this._handleTouch)
    this._map._container.addEventListener('click', this._handleTouch)

    DomEvent.on(
      this._map._container,
      'wheel',
      this._handleScroll,
      this
    )
    this._map._container.addEventListener('mouseenter', this._handleMouseOver)
    this._map._container.addEventListener('mouseleave', this._handleMouseOut)

    // Listen to these events so will not disable dragging if the user moves the mouse out the boundary of the map container whilst actively dragging the map.
    DomEvent.on(this._map, 'movestart', this._handleDragging, this)
    DomEvent.on(this._map, 'move', this._handleDragging, this)
    DomEvent.on(this._map, 'moveend', this._handleDragging, this)
  },
  removeHooks: function () {
    this._enableInteractions()

    this._map._container.removeEventListener(
      'touchstart',
      this._handleTouch
    )
    this._map._container.removeEventListener(
      'touchmove',
      this._handleTouch
    )
    this._map._container.removeEventListener('touchend', this._handleTouch)
    this._map._container.removeEventListener(
      'touchcancel',
      this._handleTouch
    )
    this._map._container.removeEventListener('click', this._handleTouch)

    DomEvent.off(
      this._map._container,
      'wheel',
      this._handleScroll,
      this
    )
    this._map._container.addEventListener('mouseenter', this._handleMouseOver)
    this._map._container.addEventListener('mouseleave', this._handleMouseOut)

    DomEvent.off(this._map, 'movestart', this._handleDragging, this)
    DomEvent.off(this._map, 'move', this._handleDragging, this)
    DomEvent.off(this._map, 'moveend', this._handleDragging, this)
  },

  _handleDragging: function (e) {
    if (e.type === 'movestart' || e.type === 'move') {
      draggingMap = true
    } else if (e.type === 'moveend') {
      draggingMap = false
    }
  },

  _disableInteractions: function () {
    this._map.dragging.disable()
    this._map.scrollWheelZoom.disable()
    if (this._map.tap) {
      this._map.tap.disable()
    }
  },

  _enableInteractions: function () {
    this._map.dragging.enable()
    this._map.scrollWheelZoom.enable()
    if (this._map.tap) {
      this._map.tap.enable()
    }
  },

  _setupPluginOptions: function () {
    //For backwards compatibility, merge gestureHandlingText into the new options object
    if (this._map.options.gestureHandlingText) {
      this._map.options.gestureHandlingOptions.text = this._map.options.gestureHandlingText
    }
  },

  _setLanguageContent: function () {
    let languageContent
    //If user has supplied custom language, use that
    if (
      this._map.options.gestureHandlingOptions &&
      this._map.options.gestureHandlingOptions.text &&
      this._map.options.gestureHandlingOptions.text.touch &&
      this._map.options.gestureHandlingOptions.text.scroll &&
      this._map.options.gestureHandlingOptions.text.scrollMac
    ) {
      languageContent = this._map.options.gestureHandlingOptions.text
    } else {
      //Otherwise auto set it from the language files

      //Determine their language e.g fr or en-US
      let lang = this._getUserLanguage()

      //If we couldn't find it default to en
      if (!lang) {
        lang = 'en'
      }

      //Lookup the appropriate language content
      if (LanguageContent[lang]) {
        languageContent = LanguageContent[lang]
      }

      //If no result, try searching by the first part only. e.g en-US just use en.
      if (!languageContent && lang.indexOf('-') !== -1) {
        lang = lang.split('-')[0]
        languageContent = LanguageContent[lang]
      }

      if (!languageContent) {
        // If still nothing, default to English
        // console.log("No lang found for", lang);
        lang = 'en'
        languageContent = LanguageContent[lang]
      }
    }

    //TEST
    // languageContent = LanguageContent["bg"];

    //Check if they're on a mac for display of command instead of ctrl
    const mac = navigator.platform.toUpperCase().indexOf('MAC') >= 0

    const scrollContent = mac ? languageContent.scrollMac : languageContent.scroll

    this._map._container.setAttribute(
      'data-gesture-handling-touch-content',
      languageContent.touch
    )
    this._map._container.setAttribute(
      'data-gesture-handling-scroll-content',
      scrollContent
    )
  },

  _getUserLanguage: function () {
    return navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage
  },

  _handleTouch: function (e) {
    //Disregard touch events on the minimap if present
    const ignoreList = [
      'leaflet-control-minimap',
      'leaflet-interactive',
      'leaflet-popup-content',
      'leaflet-popup-content-wrapper',
      'leaflet-popup-close-button',
      'leaflet-control-zoom-in',
      'leaflet-control-zoom-out'
    ]

    const ignoreElement = !!ignoreList.find(c => DomUtil.hasClass(e.target, c))

    if (ignoreElement) {
      if (
        DomUtil.hasClass(e.target, 'leaflet-interactive') &&
        e.type === 'touchmove' &&
        e.touches.length === 1
      ) {
        DomUtil.addClass(this._map._container,
          'leaflet-gesture-handling-touch-warning'
        )
        this._disableInteractions()
      } else {
        DomUtil.removeClass(this._map._container,
          'leaflet-gesture-handling-touch-warning'
        )
      }
      return
    }
    // screenLog(e.type+' '+e.touches.length);
    if (e.type !== 'touchmove' && e.type !== 'touchstart') {
      DomUtil.removeClass(this._map._container,
        'leaflet-gesture-handling-touch-warning'
      )
      return
    }
    if (e.touches.length === 1) {
      DomUtil.addClass(this._map._container,
        'leaflet-gesture-handling-touch-warning'
      )
      this._disableInteractions()
    } else {
      e.preventDefault()
      this._enableInteractions()
      DomUtil.removeClass(this._map._container,
        'leaflet-gesture-handling-touch-warning'
      )
    }
  },

  _isScrolling: false,

  _handleScroll: function (e) {
    if (e.metaKey || e.ctrlKey) {
      e.preventDefault()
      DomUtil.removeClass(this._map._container,
        'leaflet-gesture-handling-scroll-warning'
      )
      this._map.scrollWheelZoom.enable()
    } else {
      DomUtil.addClass(this._map._container,
        'leaflet-gesture-handling-scroll-warning'
      )
      this._map.scrollWheelZoom.disable()

      clearTimeout(this._isScrolling)

      if (this._map.options.gestureHandlingOptions) {
        // Set a timeout to run after scrolling ends
        this._isScrolling = setTimeout(function () {
          // Run the callback
          const warnings = document.getElementsByClassName(
            'leaflet-gesture-handling-scroll-warning'
          )
          for (let i = 0; i < warnings.length; i++) {
            DomUtil.removeClass(warnings[i],
              'leaflet-gesture-handling-scroll-warning'
            )
          }
        }, this._map.options.gestureHandlingOptions.duration)
      }
    }
  },

  _handleMouseOver: function (e) {
    this._enableInteractions()
  },

  _handleMouseOut: function (e) {
    if (!draggingMap) {
      this._disableInteractions()
    }
  }

})

Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)
